import React, {useState} from 'react'
import wallet_logo from '../../assets/img/wallet_logo.png'
import farm_logo from '../../assets/img/farm_logo.png'

const FarmBody = () => {
    const [farm] = useState([
        {name: 'Tevah chain-BNB', img:farm_logo, earned: 0, apr:'2,000.14%', liquidity:'$20', multiplier:'0.5x'},
        {name: 'Tevah chain-BUSD', img:farm_logo, earned: 0, apr:'2,000.14%', liquidity:'$20', multiplier:'0.5x'},
        {name: 'Tevah chain-BNB', img:farm_logo, earned: 0, apr:'1,000.14%', liquidity:'$10', multiplier:'0.8x'},
        {name: 'Tevah chain-BNB', img:farm_logo, earned: 0, apr:'2,000.14%', liquidity:'$20', multiplier:'0.5x'},
        {name: 'Tevah chain-BTA', img:farm_logo, earned: 0, apr:'3,000.14%', liquidity:'$70', multiplier:'1.5x'},
        {name: 'Tevah chain-BNB', img:farm_logo, earned: 0, apr:'2,000.14%', liquidity:'$20', multiplier:'0.5x'},
    ])
  return (
    <>
        <div>
            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col-lg-8 col-md-8 col-sm-12">
                        <h1 className="text-start text-white">FARMS</h1>
                        <h5 className="text-white text-start">Stake Liquidity Pool (LP) tokens to earn.</h5>
                    </div>
                    <div className="col-lg-4 col-md-4 d-flex justify-content-center align-items-center col-sm-12">
                        <img src={wallet_logo} alt="wallet_logo" />
                        <h5 className="text-white text-start">$000.00</h5>
                    </div>

                </div>
            </div>

            <div className="farmss">
                <div className="container row">
                    <div className="col-lg-6">
                        <div className="col-6 d-flex bg-secondary mt-3">
                            <button className="btn btn-primary" style={{width:'68px !important'}}>Live</button>
                            <button className="btn btn-default">Finished</button>
                        </div>
                    </div>
                    <div className="col-lg-6 mt-1 row">
                        <div className="col-5 form-group">
                            <label htmlFor="" className="text-white">SORT BY </label>
                            <select name="select" id="select" className="form-control">
                                <option value="Earned">Earned</option>
                                <option value="Hot">Hot</option>
                                <option value="APR">APR</option>
                                <option value="Multiplier">Multiplier</option>
                                <option value="Liquidity">Liquidity</option>
                            </select>
                        </div>
                        <div className="col-7 from-group">
                            <label htmlFor="" className="text-white">SEARCH </label>
                            <input type="text" className="form-control" placeholder='Search Farms' />
                        </div>
                    </div>

                    <div className="row farms">
                    {farm && farm.map((fa, index) =>(
                        <div className="col-lg-12 row mt-4 text-center" style={{borderBottom: '1px solid #014FAA'}} key={index}>
                            <div className="col-lg-3 col-sm-8 d-flex justify-content-center align-items-center">
                                <img src={fa.img} alt={fa.name} />
                                <div className="text-primary" style={{fontWeight: 'bold', fontSize: '14px',textTransform: 'uppercase'}}>{fa.name}</div>
                            </div>
                            <div className="col-lg-1 col-sm-4">
                                <div className="form-group">
                                    <label htmlFor="">Earned</label>
                                    <p className="text-center">{fa.earned}</p>
                                </div>
                            </div>
                            <div className="col-lg-2 sm-hidden">
                                <div className="form-group">
                                    <label htmlFor="">APR</label>
                                    <p className="text-center">{fa.apr}</p>
                                </div>
                            </div>
                            <div className="col-lg-2 sm-hidden">
                                <div className="form-group">
                                    <label htmlFor="">Liquidity</label>
                                    <p className="text-center">{fa.liquidity}</p>
                                </div>
                            </div>
                            <div className="col-lg-2 sm-hidden">
                                <div className="form-group">
                                    <label htmlFor="">Multiplier</label>
                                    <p className="text-center">{fa.multiplier}</p>
                                </div>
                            </div>
                            <div className="col-lg-2 btn btn-default text-primary">Details</div>
                        </div>
                    ))}
                    <br />
                </div>
                </div>
                <br />
                <br />
            </div>
        </div>
    </>
  )
}

export default FarmBody