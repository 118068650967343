import React from 'react'
import FarmBody from '../Components/AppComponent/FarmBody'
import Navbar from '../Components/AppComponent/Navbar'
import Sidenav from '../Components/AppComponent/Sidenav'
import './css/Dashboard.css'

const Farm = () => {
  return (
    <>
        <div className="primary" id="dashboard">
            <Navbar />
            <div className="row">
                <div className="col-lg-3">
                    <Sidenav />
                </div>
                <div className="col-lg-9 col-sm-12 bg-primary" style={{height: 'auto'}}>
                    <FarmBody />
                </div>
            </div>
        </div>
    </>
  )
}

export default Farm