import React from 'react'
import mark from '../../assets/svg/mark.svg'

const SectionTwo = () => {
  return (
    <>
      <div className="container section_two">
        <div className="row column">
          <div className="col-lg-6 col-sm-12">
            <p className="sec_p text-white text-start">
            <b>Tevah chain</b> is the fastest blockchain in the world 
            and the fastest growing ecosystem in crypto, 
            with thousands of projects spanning DeFi, NFTs, 
            Web3 and more.
            </p>
            <button className="sec_btn btn btn-default">EXPLORE THE ECOSYSTEM</button>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="h2">Join the fastest growing ecosystem</div>
          </div>
        </div>

        <div className="row justify-content-center align-items-center mt-5">
          <h2 className="text_h2 text-white text-center mt-5">
            Build on Tevah Chain
          </h2>
          <p className="text_p text-white text-center mt-3">Learn how to build your dApps on top of Tevah Smart Chain.</p>
        </div>
        
        <div className="row mt-5">
          <div className="col-sm-12 row mt-5 col-lg-4">
            <div className="col-12">
              <div className="h2s text-white text-start">
                Build dApps
              </div>
              <p className="text-white ps text-start">
                Learn the fundamentals
                Start experimenting codes
                Tutorial
                Developer resources
              </p>
            </div>
            <div className="col-12 mt-5">
              <div className="h2s text-white text-start">
              Most Valuable Builders (MVB)
              </div>
              <p className="text-white ps text-start">
              Most Valuable Builders (MVB)
              Tevah Chain accelerator fund
              BUIDL reward leaderboard
              </p>
            </div>
          </div>
          <div className="col-sm-12 mt-5 col-lg-4 md-hidden">
            <img src={mark} alt="mark" className="img-fluid"  />
          </div>
          <div className="col-sm-12 row mt-5 col-lg-4">
            <div className="col-12">
                <div className="h2s text-white text-start">
                Integrate Existing Solutions
                </div>
                <p className="text-white ps text-start">
                Ecosystem
                Tevah Chain Integration
                </p>
            </div>
            <div className="col-12 mt-5">
              <div className="h2s text-white text-start">
              Join the Developer Community
              </div>
              <p className="text-white ps text-start">
                <a className="text-white" href="#">Developer discord</a> <br />
                <a className="text-white" href="#">Developer telegram</a> <br />
                <a className="text-white" href="#">Tevah Chain Youtube</a> <br />
                <a className="text-white" href="#">Tevah Chain MVB Program Telegram</a>
              </p>
            </div>
          </div>
        </div>


        <div className="container mt-5">
            <div className="row flex mt-5">
                <div className="col-lg-6 col-md-6 mt-5 col-sm-12 widget_one">
                    <h2>
                      Low cost, 
                      forever
                    </h2>
                    <p className="text-white">
                      Open Ark’s scalability ensures 
                      transactions remain less than $0.01 
                      for both developers and users.
                    </p>
                </div>
                <div className="col-lg-6 col-md-6 mt-5 col-sm-12 widget_two">
                    <h2>
                    Fast, forever
                    </h2>
                    <p className="text-white">
                    Open Ark’s is all about speed, with 
                    400 millisecond block times. 
                    And as hardware gets faster, 
                    so does the network.
                    </p>
                </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default SectionTwo