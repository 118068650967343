import React from 'react'
import Hero from '../Components/HomeComponent/Hero'
import { Navbar } from '../Components/HomeComponent/Navbar'
import SectionOne from '../Components/HomeComponent/SectionOne'
import SectionTwo from '../Components/HomeComponent/SectionTwo'
import SectionThree from '../Components/HomeComponent/SectionThree'
import Footer from '../Components/HomeComponent/Footer'
import './css/Home.css'

const Home = () => {
  return (
    <>
        <div>
            <Navbar />
            <Hero />
            <SectionOne />
            <SectionTwo />
            <SectionThree />
            <Footer />
        </div>
    </>
  )
}

export default Home