import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Home from './Pages/Home';
import Dashboard from './Pages/Dashboard';
import Farm from './Pages/Farm';
import Pool from './Pages/Pool';


// import AppContext from './Contexts/context';



function App() {
  return (
    <Router>
          <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route exact path="/farm" element={<Farm />} />
              <Route exact path="/pool" element={<Pool />} />
          </Routes>
    </Router>
  );
}

export default App;