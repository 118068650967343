import React from 'react'
import discord from '../../assets/img/discord.png'
import medium from '../../assets/img/medium.png'
import linkedin from '../../assets/img/linkedin.png'
import twitter from '../../assets/img/twitter.png'
import telegram from '../../assets/img/telegram.png'
import redit from '../../assets/img/redit.png'

const Footer = () => {
  return (
    <>
      <footer className="continer-fluid footer pb-5">
        {/* join community */}
        <div className="container-fluid row justify-content-center align-items-center mt-5">
          <h2 className="text-white text-center col-12">Join the community</h2>
          <div className="row links justify-content-center align-items-center">
            <div className="col-2">
              <a href="#">
                <img src={telegram} alt="telegram" />
              </a>
            </div>
            <div className="col-2">
              <a href="#">
                <img src={twitter} alt="twitter" />
              </a>
            </div>
            <div className="col-2">
              <a href="#">
                <img src={linkedin} alt="linkedin" />
              </a>
            </div>
            <div className="col-2">
              <a href="#">
                <img src={redit} alt="redit" />
              </a>
            </div>
            <div className="col-2">
              <a href="#">
                <img src={medium} alt="medium" />
              </a>
            </div>
            <div className="col-2">
              <a href="#">
                <img src={discord} alt="discord" />
              </a>
            </div>
          </div>
        </div>

        {/* footer */}
        <footer className="container footers mb-5">
          <div className="row mt-5 mb-5">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <h5 className="text-center text-white">
              Get the latest Tevah chain updates
              </h5>
              <div className="form-group d-flex">
                <input type="email" className="form-control" />
                <button className="btn btn-primary">I'm in</button>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-12 pt-3">
              <h5 className="text-start text-white">
                Developer
              </h5>
              <p className="text-white text-start">
                <a href="#" className="text-white">Github</a> <br />
                <a href="#" className="text-white">SDKs</a> <br />
                <a href="#" className="text-white">Tevah Chain MVB Program</a>
              </p>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-12 pt-3">
              <h5 className="text-start text-white">
              Explore
              </h5>
              <p className="text-white text-start">
                <a href="#" className="text-white">Fiat on Ramp</a> <br />
                <a href="#" className="text-white">Wallets</a> <br />
                <a href="#" className="text-white">Exchange Support</a> <br />
                <a href="#" className="text-white">Proof of Assets  </a>
              </p>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-12 pt-3">
              <h5 className="text-start text-white">
              Community
              </h5>
              <p className="text-white text-start">
                <a href="#" className="text-white">Events/News</a> <br />
                <a href="#" className="text-white">Support</a> <br />
                <a href="#" className="text-white">Martian Program</a> <br />
                <a href="#" className="text-white">Hackathon  </a>
              </p>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-12 pt-3">
            <h5 className="text-start text-white">
            Technology
              </h5>
              <p className="text-white text-start">
                <a href="#" className="text-white">Tevah chain DEX</a> <br />
                <a href="#" className="text-white">Tevah Smart Chain</a> <br />
                <a href="#" className="text-white">Staking</a> <br />
                <a href="#" className="text-white">Token Management  </a>
              </p>
            </div>
          </div>
        </footer>

        <div className="container mt-5">
          <div className="row justify-content-space-between">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <p className="text-start text-white">
              {new Date().getFullYear()} . All Rights Reserved
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <p className="text-white row justify-content-center align-items-center">
                <a href="#" className="text-white col-lg-6 col-sm-12 text-lg-end">Privacy Policy</a>
                <a href="#" className="text-white col-lg-6 col-sm-12 text-lg-start">Terms of Service</a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer