import React from 'react'
import home from '../../assets/img/home.png'
import pool from '../../assets/img/pool.png'
import trade from '../../assets/img/trade.png'
import more from '../../assets/img/more.png'
import wallet_logo from '../../assets/img/wallet_logo.png'
import { Link } from 'react-router-dom'

const Sidenav = () => {
  return (
    <>
        <div className=" collapse navbar-collapse" id="mynavbar">
            <nav className="sidebar navbar bg-light">
                <div className="container">
                    <ul className="navbar-nav">
                        <Link to="/dashboard" className="nav-item">
                            <a className="nav-link active" href="/dashboard">
                            <img src={home} alt="home" />&nbsp; &nbsp;Home</a>
                        </Link>
                        <Link to="/farm" className="nav-item">
                            {/* <img src="" alt="farm" /> */}
                            <a className="nav-link" href="/farm">&nbsp; &nbsp;Farm</a>
                        </Link>
                        <Link to="/pool" className="nav-item">
                            <a className="nav-link" href="/pool">
                            <img src={pool} alt="pool" />&nbsp; &nbsp;Pool</a>
                        </Link>
                        <li className="nav-item text-white dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">
                            <img src={trade} alt="trade" />&nbsp; &nbsp;Trade</a>
                            <ul className="dropdown-menu bg-primary">
                                <Link to="/Exchange" className="dropdown-item">Exchange</Link>
                                <Link to="/Liquidity" className="dropdown-item">Liquidity</Link>
                            </ul>
                        </li>
                        <Link to="/" className="nav-item">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">
                            <img src={more} alt="more" />&nbsp; &nbsp;More</a>
                            <ul className="dropdown-menu bg-primary">
                                <Link to="/Contact" className="dropdown-item">Contact</Link>
                                <Link to="/Github" className="dropdown-item">Github</Link>
                                <Link to="/Blog" className="dropdown-item">Blog</Link>
                            </ul>
                        </Link>
                        <div className="d-flexs">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-6 d-flex align-items-center">
                                    <img src={wallet_logo} alt="wallet_logo" />
                                    <h5 className="text-start">$000.00</h5>
                                </div>&nbsp;&nbsp;
                                <div className="col-6 row">
                                    <i className="bi bi-telegram text-primary col-6" style={{fontSize:'20px'}}></i>
                                    <i className="bi bi-twitter text-primary col-6" style={{fontSize:'20px'}}></i>
                                </div>
                            </div>
                            <div className="container-fluid row justify-content-between align-items-center">
                                <div className="col-6 d-flex">
                                    <i className="bi bi-sun text-primary col-6" style={{fontSize:'20px'}}> /</i>
                                    <i className="bi bi-moon text-secondary col-6" style={{fontSize:'20px'}}></i>
                                </div>&nbsp;&nbsp;
                                <div className="col-6 row">
                                    <i className="bi bi-globe text-secondary col-6" style={{fontSize:'20px'}}></i>
                                    <i className="bi bi-language text-primary col-6" style={{fontSize:'20px'}}>EN</i>
                                </div>
                            </div>
                        </div>
                    </ul>
                </div>
            </nav>
        </div>
    </>
  )
}

export default Sidenav