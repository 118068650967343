import React from 'react'

const MainBody = () => {
  return (
    <>
        <div className="container-fluid mt-5">
          <div className="row justify-content-center align-items-center">
            <h1 className="text-center text-white">Tevah chain</h1>
            <h5 className="text-white text-center">Bringing the true meaning of DeFi to you.</h5>
          </div>

          <div className="row mt-4">
            <div className="col-md-6 col-lg-6 col-sm-12 farm">
                <h3 className="text-center text-primary">
                Farms & Staking
                </h3>

                <div className="mt-5 form">
                  <div className="form-group mt-3">
                    <label htmlFor="" className="text-primary">Tevah chain to Harvest:</label>
                    <input type="text" readOnly value="LOCKED" className="text-center form-control" />
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="" className="text-primary">Tevah chain in Wallet:</label>
                    <input type="text" readOnly value="LOCKED" className="text-center form-control" />
                  </div>
                  <button className="btn btn-primary mx-auto d-block mt-5 w-100" style={{boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius:'10px !important'}}>UNLOCK WALLET</button>
                </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-8 row col-md-8 col-sm-12 justify-content-center">
                <div style={{borderRadius:'40px',padding:'10px'}} className="card col-lg-5 col-sm-12">
                  <div className="card-header">
                    <h5 className="text-start">EARN</h5>
                  </div>
                  <div className="card-body">
                    <h3 className="text-center text-primary">Tevah chain</h3>
                  </div>
                  <div className="card-footer d-flex justify-content-between">
                    <p className="text-start">IN POOL</p>
                    <i className="bi bi-arrow-right text-primary"></i>
                  </div>
                </div>
                <div style={{borderRadius:'40px',padding:'10px', marginLeft:'10px'}} className="card col-lg-5 col-sm-12">
                  <div className="card-header">
                    <h5 className="text-start">EARN UPTO</h5>
                  </div>
                  <div className="card-body">
                    <h3 className="text-center text-primary">
                    1000% APR
                    </h3>
                  </div>
                  <div className="card-footer d-flex justify-content-between">
                    <p className="text-start">IN Farms</p>
                    <i className="bi bi-arrow-right text-primary"></i>
                  </div>
                </div>
            </div>
          </div>
          <br /> <br />
        </div>
    </>
  )
}

export default MainBody