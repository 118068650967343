import React,{useState} from 'react'
import farm_logo from '../../assets/img/farm_logo.png'
import wallet_logo from '../../assets/img/wallet_logo.png'

const PoolBody = () => {
  const [farm] = useState([
    {title:'Auto Tevah chain', name: 'Automatic restaking', img:farm_logo, earned: 0, apr:'20.14%', stake:'20977.90 APKS', end:'-'},
    {title:'manual Tevah chain', name: 'Manual restaking', img:farm_logo, earned: 0, apr:'20.14%', stake:'20977.90 APKS', end:'-'},
    {title:'Auto Tevah chain', name: 'Automatic restaking', img:farm_logo, earned: 0, apr:'20.14%', stake:'20977.60 APKS', end:'-'}
])
  return (
    <>
      <div>
          <div className="container-fluid mt-5">
            <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-12">
                    <h1 className="text-start text-white">Soup Pools</h1>
                    <h5 className="text-white text-start">Just stake some tokens to earn. <br />
High APR, low risk.</h5>
                </div>
                <div className="col-lg-4 col-md-4 d-flex justify-content-center align-items-center col-sm-12">
                    <img src={wallet_logo} alt="wallet_logo" />
                    <h5 className="text-white text-start">$000.00</h5>
                </div>

            </div>
          </div>

          <div className="farmss">
              <div className="container row">
                  <div className="col-lg-6">
                      <div className="col-6 d-flex bg-secondary mt-3">
                          <button className="btn btn-primary" style={{width:'68px !important'}}>Live</button>
                          <button className="btn btn-default">Finished</button>
                      </div>
                  </div>
                  <div className="col-lg-6 mt-1 row">
                      <div className="col-5 form-group">
                          <label htmlFor="" className="text-white">SORT BY </label>
                          <select name="select" id="select" className="form-control">
                              <option value="Earned">Earned</option>
                              <option value="Hot">Hot</option>
                              <option value="APR">APR</option>
                              <option value="Multiplier">Multiplier</option>
                              <option value="Liquidity">Liquidity</option>
                          </select>
                      </div>
                      <div className="col-7 from-group">
                          <label htmlFor="" className="text-white">SEARCH </label>
                          <input type="text" className="form-control" placeholder='Search Farms' />
                      </div>
                  </div>

                  <div className="row farms">
                  {farm && farm.map((fa, index) =>(
                      <div className="col-lg-12 row mt-4 text-center" style={{borderBottom: '1px solid #014FAA'}} key={index}>
                          <div className="col-lg-3 col-sm-8 d-flex justify-content-center align-items-center">
                              <img src={fa.img} alt={fa.name} />
                              <div>
                              <div className="text-primary text-start" style={{fontWeight: '600', fontSize: '13px',textTransform: 'uppercase'}}>{fa.title}</div>
                              <p className="text-primary text-start">{fa.name}</p>
                              </div>
                          </div>
                          <div className="col-lg-2 col-sm-4">
                              <div className="form-group">
                                  <label htmlFor="">Recent Earned</label>
                                  <p className="text-center">{fa.earned}</p>
                              </div>
                          </div>
                          <div className="col-lg-1 sm-hidden">
                              <div className="form-group">
                                  <label htmlFor="">APR</label>
                                  <p className="text-center">{fa.apr}</p>
                              </div>
                          </div>
                          <div className="col-lg-2 sm-hidden">
                              <div className="form-group">
                                  <label htmlFor="">Total Stake</label>
                                  <p className="text-center">{fa.stake}</p>
                              </div>
                          </div>
                          <div className="col-lg-2 sm-hidden">
                              <div className="form-group">
                                  <label htmlFor="">Ends In</label>
                                  <p className="text-center">{fa.end}</p>
                              </div>
                          </div>
                          <div className="col-lg-2 btn btn-default text-primary">Details</div>
                      </div>
                  ))}
                  <br />
              </div>
              </div>
              <br />
              <br />
          </div>
      </div>
    </>
  )
}

export default PoolBody