import React from 'react'
import logo from '../../assets/img/logo.png'
const Navbar = () => {
  return (
    <>
        <nav className="navbar top-nav navbar-expand-sm navbar-light sticky-top bg-light">
            <div className="container-fluid">
                <div className="d-flex">
                    <button className="btn btn-default" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <a className="navbar-brand" href="#">
                        <img src={logo} alt="logo" />
                    </a>
                    <ul className="navs navbar-nav">
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle text-light" href="#" role="button" data-bs-toggle="dropdown">Developers</a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Tevah chain Chain Developers</a></li>
                                <li><a className="dropdown-item" href="#">Github</a></li>
                                <li><a className="dropdown-item" href="#">Docs</a></li>
                                <li><a className="dropdown-item" href="#">Tevah chain Chain Accelerator Fund</a></li>
                                <li><a className="dropdown-item" href="#">Tevah chain Reward Leaderborad</a></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle text-light" href="#" role="button" data-bs-toggle="dropdown">Networks</a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Network Status</a></li>
                                <li><a className="dropdown-item" href="#">RPC Provider</a></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle text-light" href="#" role="button" data-bs-toggle="dropdown">Community</a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Activities</a></li>
                                <li><a className="dropdown-item" href="#">Events & News</a></li>
                                <li><a className="dropdown-item" href="#">Hackathon</a></li>
                                <li><a className="dropdown-item" href="#">Support</a></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle text-light" href="#" role="button" data-bs-toggle="dropdown">Explorer</a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Tevah chain Scan</a></li>
                                <li><a className="dropdown-item" href="#">Tevah chain Trace</a></li>
                                <li><a className="dropdown-item" href="#">Bitquery</a></li>
                                <li><a className="dropdown-item" href="#">Tevah chain Beacon Chain Explorer</a></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle text-light" href="#" role="button" data-bs-toggle="dropdown">Exchange</a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Tokens </a></li>
                                <li><a className="dropdown-item" href="#">MiniTokens</a></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-light" href="#">Ecosystem</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-light" href="#">Staking</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-light" href="#">Wallet</a>
                        </li>
                        
                        <li className="nav-item">
                            <a className="nav-link text-light" href="#">Blog</a>
                        </li>
                    </ul>
                    <div className="d-flex" style={{float: 'flex-end', height:'50px', width:'233px !important'}}>
                        <button className="btn btn-primary" type="button">Connect</button>
                    </div>
                </div>
            </div>
        </nav>
    </>
  )
}

export default Navbar