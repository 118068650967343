import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/svg/logo.svg'

export const Navbar = () => {
  return (
    <>
        <nav className="navbar navbar-expand-lg navbar-dark">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">
                    <img src={logo} alt="logo" />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="collapsibleNavbar">
                    <ul className="navbar-nav">
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">Developers</a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Tevah chain Chain Developers</a></li>
                                <li><a className="dropdown-item" href="#">Github</a></li>
                                <li><a className="dropdown-item" href="#">Docs</a></li>
                                <li><a className="dropdown-item" href="#">Tevah chain Chain Accelerator Fund</a></li>
                                <li><a className="dropdown-item" href="#">Tevah chain Reward Leaderborad</a></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">Networks</a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Network Status</a></li>
                                <li><a className="dropdown-item" href="#">RPC Provider</a></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">Community</a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Activities</a></li>
                                <li><a className="dropdown-item" href="#">Events & News</a></li>
                                <li><a className="dropdown-item" href="#">Hackathon</a></li>
                                <li><a className="dropdown-item" href="#">Support</a></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">Explorer</a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Tevah chain Scan</a></li>
                                <li><a className="dropdown-item" href="#">Tevah chain Trace</a></li>
                                <li><a className="dropdown-item" href="#">Bitquery</a></li>
                                <li><a className="dropdown-item" href="#">Tevah chain Beacon Chain Explorer</a></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">Exchange</a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Tokens </a></li>
                                <li><a className="dropdown-item" href="#">MiniTokens</a></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">Ecosystem</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">Staking</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">Wallet</a>
                        </li>
                        
                        <li className="nav-item">
                            <a className="nav-link" href="#">Blog</a>
                        </li>
                    </ul>
                    <div className="d-flex">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link to="/dashboard" className="nav-link">App</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    </>
  )
}
