import React from 'react'
import Navbar from '../Components/AppComponent/Navbar'
import PoolBody from '../Components/AppComponent/PoolBody'
import Sidenav from '../Components/AppComponent/Sidenav'
import './css/Dashboard.css'

const Pool = () => {
  return (
    <>
        <div className="primary" id="dashboard">
            <Navbar />
            <div className="row">
                <div className="col-lg-3">
                    <Sidenav />
                </div>
                <div className="col-lg-9 col-sm-12 bg-primary" style={{height: 'auto'}}>
                    <PoolBody />
                </div>
            </div>
        </div>
    </>
  )
}

export default Pool