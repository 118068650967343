import React, {useState} from 'react'
import chain from '../../assets/img/chain.png'
import link from '../../assets/img/link.png'
import wallet from '../../assets/img/wallet.png'
import blocks from '../../assets/img/blocks.png'
import caro_one from '../../assets/img/caro_one.png'
import caro_two from '../../assets/img/caro_two.png'
import caro_three from '../../assets/img/caro_three.png'
const SectionOne = () => {
    const [widget_one] = useState([
        {id:1, title:'Explore the dapps', img:chain},
        {id:2, title:'Build on Tevah Chain', img:link}
    ]) 
    const [widget_three] = useState([
        {id:1, title:'Trade on Tevah chain dex', img:blocks},
        {id:2, title:'Buy Crypto', img:wallet}
    ]) 
    const [caroul] = useState([
        {id:2, img: caro_two, title:'Buy Crypto', step:'Step 2', text:'Withdraw the assets to a Tevah Chain supported wallet after your purchase.', btn:'BUY'},
        {id:3, img: caro_three, title:'Explore Tevah chain Chain Projects', step:'Step 3', text:'Please note that Tevah Chain is a decentralized public chain that provides a block network, but it cannot manage the decentralized projects issued on the chain. It is important to understand the project rules and evaluate the project risk.', btn:'BROWSE'},
    ])
    // console.log(widget_one)
  return (
    <>
        <div className="container-fluid section-one">
            <div className="container-fluid">
                <div className="row justify-content-center align-items-center">
                    <h2 className="text-center text-white h2">
                    Decentralized and unstoppable
                    </h2>
                    <p className="text-start text-white p">
                    Not only is Tevah chain ultra-fast and low cost, it is censorship resistant. 
                    Meaning, the network will remain open for applications to run freely 
                    and transactions will never be stopped.
                    </p>
                </div>                
            </div>
            <div className="sec_one">
                <div className="sec_h2 text-white pt-5">
                    Explore and join the Tevah chain chain ecosystem
                </div>
                <p className="sec_p">
                    Enjoy the most innovative NFT and DeFi dApps. Fast, decentralized, affordable and secure
                </p>
            
                <div className="sec_one_two row mt-5">
                    <div className="col-sm-12 col-lg-4 mt-5 row col-md-4">
                        {widget_one && widget_one.map((wid, index)=>(
                            <div className="col-md-6 col-sm-12 text-center" key={index}>
                                <img src={wid.img} alt={wid.id} />
                                <p className="text-center text-white">
                                    {wid.title}
                                </p>
                            </div>
                        ))}
                    </div>
                    <div className="col-sm-12 col-lg-4 mt-5 col-md-4">
                        <div id="demo" className="carousel slide" data-bs-ride="carousel">
                            <div className="carousel-indicators">
                                <button type="button" data-bs-target="#demo" data-bs-slide-to="0" className="active"></button>
                                <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
                                <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
                            </div>
                            <div className="carousel-inner">
                                
                                <div className="carousel-item active">
                                    <div className="card">
                                        <div className="card-header text-center">
                                            <img src={caro_one} alt={'caro'} />
                                            
                                        </div>
                                        <div className="card-body pt-5">
                                            <div className="h2 text-white text-center">
                                                Download Wallet
                                            </div>
                                            <p className="text-white text-center">
                                                Supports Binance Wallet, Trust Wallet, 
                                                MetaMask, Math Wallet, MEW wallet, 
                                                imToken and other wallets.
                                            </p>
                                        </div>
                                        <div className="card-footer text-center">
                                            <button className="btn btn-secondary">DOWNLOAD</button>
                                        </div>
                                    </div>
                                </div>
                                {caroul && caroul.map((caro,index)=>(
                                    <div className="carousel-item" key={index}>
                                        <div className="card">
                                            <div className="card-header text-center">
                                                <img src={caro.img} alt={caro.id} />
                                                
                                            </div>
                                            <div className="card-body">
                                                <div className="h2 text-white text-center">
                                                    {caro.title}
                                                </div>
                                                <p className="text-white text-center">
                                                    {caro.text}
                                                </p>
                                            </div>
                                            <div className="card-footer text-center">
                                                <button className="btn btn-secondary">{caro.btn}</button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon"></span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
                                <span className="carousel-control-next-icon"></span>
                            </button>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-4 mt-5 row col-md-4">
                        {widget_three && widget_three.map((wids, index)=>(
                            <div className="col-md-6 col-sm-12 text-center" key={index}>
                                <img src={wids.img} alt={wids.id} className="" />
                                <p className="text-center text-white">
                                    {wids.title}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>        
    </>
  )
}

export default SectionOne