import React from 'react'
import head from '../../assets/img/head.png'

const Hero = () => {
  return (
    <>
        <div className="container-fluid mt-5 hero">
            <div className="row mt-5">
                <div className="col-lg-6 col-sm-12 md-hidden">
                    <img src={head} alt="head" style={styles.img} />
                </div>
                <div className="col-lg-6 col-sm-12">
                    <h2 className="text-white text-start hero_h2" style={styles.hero_h2}>
                    Secure and Decentralised Trading with <b>Tevah chain </b> 
                    powerful for developers.
                    fast for everyone.
                    </h2>

                    <p className='text-start' style={styles.hero_p}>
                    We aim to be the most complete defi platform 
                    covering all aspect of defi and putting defi 
                    within the reach of millions.
                    </p>

                    <div className="d-flex hero_btns justify-content-space-around">
                        <button className="btn btn-success">TEVAH CHAIN WHITEPAPER</button>
                        <button className="btn btn-primary">START BUILDING</button>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Hero
const styles = {
    hero_h2:{
        fontWeight: '700',
        fontStyle: 'normal',
        letterSpacing: '1.5px',
        // textTransform: 'uppercase',
        fontSize: '35px',
        lineHeight: '70px',
        fontFamily: 'Arial, Helvetica, sans-Roboto'
    },
    hero_p:{
        fontSize:"15px",
        lineHeight:'30px',
        color: '#FFFFFF',
        letterSpacing: '2px',
        marginTop: '35px'
    },
    img:{
        width:'100%',
    }
}